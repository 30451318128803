import {CONST} from "@/utils/Constants";
import mNavigationHeader from '@/modules/m-navigation-header/m-navigation-header.vue';
import oButton from '@/objects/o-button/o-button.vue';
import oHeader from '@/objects/o-header/o-header.vue';

export default {
  name: 'space',
  components: {
    'm-navigation-header': mNavigationHeader,
    'o-header': oHeader,
    'o-button': oButton
  },
  data() {
    return {
      background_text_visible: false
    };
  },
  methods: {
    setBackgroundTextVisible() {
      this.background_text_visible = true;
    }
  },
  computed: {
    zoneId() {
      return this.$store.getters.zoneId
    },
    title() {
      if (this.zoneId !== null && this.$store.getters.content && this.$route.params.spaceindex) {
        return this.$store.getters.content[this.zoneId].spaces[this.$route.params.spaceindex].title;
      }
      return '';
    },
    text() {
      if (this.zoneId !== null && this.$store.getters.content && this.$route.params.spaceindex) {
        return this.$store.getters.content[this.zoneId].spaces[this.$route.params.spaceindex].text;
      }
      return '';
    },
    background_text() {
      if (this.zoneId !== null && this.$store.getters.content && this.$route.params.spaceindex) {
        return this.$store.getters.content[this.zoneId].spaces[this.$route.params.spaceindex].background_text;
      }
      return '';
    },
    background_text_available() {
      let available = false

      if (this.background_text && this.background_text !== '') {
        available = true
      }
      if (this.background_text_visible) {
        available = false
      }
      
      return available
    },
    img_src() {
      if (this.zoneId !== null && this.$store.getters.content && this.$route.params.spaceindex) {
        // return process.env.VUE_APP_IMAGE_BASE_URL + this.$store.getters.content[this.zoneId].spaces[this.$route.params.spaceindex].header_image;
        return CONST.API.IMAGE.BASE_URL + this.$store.getters.content[this.zoneId].spaces[this.$route.params.spaceindex].header_image;
      }
      return '';
    }
  }
};

export default {
  name: 'oHeader',
  props: {
    img_src: false
  },
  methods: {
    getStyle() {
      return `background-image:url(${this.img_src});`;
    }
  }
};
